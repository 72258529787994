.container-settlements {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 998;
  width: 100%;
  top: 0;
  background: #ffffff78;

  height: 100%;

  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-box {
  /* width: 100%; */
  height: auto;
  border: 1px solid white;
  box-shadow: 1px 1px 12px 1px black;
  /* border: 1px black solid; */
  /* box-shadow: 0px 0px 10px rgba(0,0,0,.5); */
  border-radius: 5px;
  background: white;
  padding: 20px 40px;
}
.form {
  display: flex;
  margin: 15px 0;
}
.form-40 {
  display: flex;
  margin: 15px 0;
  width: 40%;
}
.form-50 {
  display: flex;
  width: 50%;
}
.form-70 {
  display: flex;
  width: 70%;
  margin: 15px 0;
}
.form-label-main {
  padding-right: 15px;
  min-width: 200px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 900;
}
.asterisk {
  color: red;
}
.form-label {
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 900;
}
.input-container {
  flex: 1;
}
.form-input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 3px;
  border: rgba(50, 50, 50, 0.3) solid 1px;
}
.form-col-2,
.form-col-3 {
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
}
.form-col-2 .form {
  width: 40%;
  margin: 0;
}
.form-col-3 .form-first {
  width: 44%;
  display: flex;
}
.form-col-3 .form {
  margin: 0;
  margin-left: 10px;
  width: 33%;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.btn-confirm {
  padding: 10px 15px;
  border-radius: 50px;
  background: orange;
  color: white;
  text-transform: uppercase;
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  letter-spacing: 1px;
}

.btn-cancel {
  background: red;
  margin-right: 10px;
}

.modal-box-assets {
  position: absolute;
  width: 700px;
  margin: auto;
  background: #fff;
  z-index: 1;
  box-shadow: 1px 1px 5px black;
  padding: 20px;
  border-radius: 5px;
}

.modal-box-container {
  flex: 1;
  padding: 10px;
}

.modal-title {
  font-size: 20px;
  color: orange;
}

.modal-account-open-link {
  text-decoration: underline;
  color: orange;
  cursor: pointer;
  position: relative;
  right: 300px;
  letter-spacing: 2px;
  background-color: none;
  border: none;
}

.notification-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.notification {
  padding: 20px;
  color: white;
  text-align: center;
}

.notification-font {
  font-size: 16pt;
}

.error {
  background: #e74c3c;
}

.success {
  background: #2ecc71;
}

.offsetList {
  padding: 5px;
  border: 1px solid #cac4c4;
  border-radius: 5px;
}

.float-right {
  float: right;
}

.offset-title {
  font-weight: 600;
  font-size: 10pt;
  color: orange;
}

.remove-offset-button {
  color: red;
}

.offset-add-button {
  font-size: 18pt;
  color: orange;
}

.offset-remove-button {
  font-size: 18pt;
  color: red;
}

.require-fields {
  border: 1px solid red;
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.font-icon-btn {
  background-color: none;
  border: none;
}
